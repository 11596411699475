import { AdvertDetails } from '@bbx/common/types/ad-detail/AdvertDetails'
import { useMapCoordinates } from '@bbx/search-journey/common/lib/useMapCoordinates'
import { verticalIdMap, verticals } from '@wh/common/chapter/types/verticals'

type GeoData =
    | {
          type: 'none'
      }
    | {
          type: 'exact'
          coordinates: [number, number]
      }
    | {
          type: 'radius'
          // center
          coordinates: [number, number]
          radius: number
      }

export const useGeoData = (advertDetails: AdvertDetails): GeoData => {
    const isEstate = advertDetails.verticalId === verticalIdMap[verticals.ESTATE]

    const { coordinates, radius, areAccurate } = useMapCoordinates(advertDetails)

    if (coordinates) {
        // Only for Estate: when radius is available, we always show a circle
        if (isEstate && radius) {
            return {
                type: 'radius',
                coordinates,
                radius,
            }
        }

        // If the coordinates are accurate, we show a marker
        // (for all verticals, but for Estate only if radius is not available)
        if (areAccurate) {
            return {
                type: 'exact',
                coordinates,
            }
        }

        // Only for Estate: if the coordinates are not accurate, we show a circle
        // with a default radius of 5000m
        if (isEstate) {
            return {
                type: 'radius',
                coordinates,
                radius: 5000,
            }
        }
    }

    return { type: 'none' }
}
